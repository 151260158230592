import React from "react";
import styles from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import { Reveal } from "react-reveal";

import image from '../../../assets/images/support/desk.webp';
import mob from '../../../assets/images/support/mob.webp';

const Banner = () => {
    const { t } = useTranslation();

    return (
        <section className={styles.wrap}>
          <Reveal effect="zoomOut">
            <div className={`${styles.container} container`}>
              <img src={image} alt="" className={`${styles.image} desk_only`} />
              <img src={mob} alt="" className={`${styles.image} mob_only`} />
              <div className={styles.textBlock}>
                <h1 className={styles.title}><Trans>{t('support.first.title')}</Trans></h1>
                <div className={styles.text}>
                  <Trans>{t('support.first.text')}</Trans>
                </div>
                <div className="mt-btn"><Button /></div>
              </div>
            </div>
          </Reveal>
        </section>
    );
};

export default Banner;