import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { Trans, useTranslation } from "react-i18next";
import 'swiper/css';
import 'swiper/css/autoplay';
import styles from './Slider.module.scss';

import image1 from '../../../assets/images/homepage/slide1.webp';
import image2 from '../../../assets/images/homepage/slide2.webp';
import image3 from '../../../assets/images/homepage/slide3.webp';
import image4 from '../../../assets/images/homepage/slide4.webp';

const Slider = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const { t } = useTranslation();
  const images = [image1, image2, image3, image4];
  const titles = t('main.slider', { returnObjects: true });

  return (
    <div className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.titles}>
          {titles.map((title, index) => (
            <Link to='/:lang/bonuses' 
              className={`${styles.link} ${index === activeSlide ? styles.linkActive : ''}`}>{title.title}</Link>
          ))}
        </div>
        <div className={styles.sliderContainer}>
          <Swiper
            // modules={[Autoplay]}
            spaceBetween={5}
            slidesPerView={1}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            onRealIndexChange={(swiper) => setActiveSlide(swiper.realIndex)} 
          >
            {titles.map((item, index) => (
              <SwiperSlide key={index} className={styles.sliderItem}>
                <div className={styles.imgWrap}>
                  <img src={images[index]} alt='img' />
                  <span className={styles.title}>{t(`${item.title}`)}</span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={styles.right}>
          <div className={styles.title}><Trans>{t('main.slider.titles')}</Trans></div>
          <div className={styles.text}><Trans>{t('main.slider.text')}</Trans></div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
