import React from "react";
import styles from './Bonus.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import { Fade } from "react-reveal";

import icon from '../../../assets/images/check.svg';

const Bonus = () => {
    const { t } = useTranslation();

    const list = t('main.five.list', { returnObjects: true });

    return (
        <section className={styles.wrap}>
          <div className={`container ${styles.container}`}>
            <div className={`${styles.left} ${styles.imgWrap}}`}>
              <div className={styles.title}>{t('main.five.title')}</div>
              <div className={styles.text}><Trans>{t('main.five.text')}</Trans></div>
              <div className="mt-btn"><Button /></div>
            </div>
            <div className={styles.right}>
              {list.map((item, index) => (
                <div className={styles.item} key={index}>
                  <i className={styles.icon}>
                    <img src={icon} alt="" />
                  </i>
                  <div className={styles.text}>{t(`${item.title}`)}</div>
                </div>
              ))}
            </div>
          </div>
        </section>
    );
};

export default Bonus;