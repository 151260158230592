import React from "react";
import styles from './List.module.scss';
import { useTranslation } from "react-i18next";
import { Reveal } from "react-reveal";

import icon from '../../../assets/images/arr-right.svg';

const List = () => {
    const { t } = useTranslation();
    const list = t('trading.second.list', { returnObjects: true });

    return (
        <section className={styles.wrap}>
          <div className={`${styles.container} container`}>
            <div className={styles.list}>
                {list.map((item, index) => (
                  <div className={styles.item} key={index}>
                    <div className={styles.left}>
                      <div className={styles.title}>{t(`${item.title}`)}</div>
                      <div className={styles.text}>{t(`${item.text}`)}</div>
                    </div>

                    <i className={styles.icon}>
                      <img src={icon} alt="" />
                    </i>
                  </div>
                ))}
              </div>
          </div>
        </section>
    );
};

export default List;