import React from "react";
import styles from './ImageText.module.scss';
import { useTranslation } from "react-i18next";
import { Reveal } from "react-reveal";

import image1 from '../../../assets/images/support/image1.webp';
import image2 from '../../../assets/images/support/image2.webp';
import image3 from '../../../assets/images/support/image3.webp';
import icon1 from '../../../assets/images/support/icon1.svg';
import icon2 from '../../../assets/images/support/icon2.svg';
import icon3 from '../../../assets/images/support/icon3.svg';

const ImageText = () => {
    const { t } = useTranslation();
    const list = t('support.second.list', { returnObjects: true });
    const icons = [icon1, icon2, icon3];

    return (
        <section className={styles.wrap}>
          <div className={`${styles.container} container`}>
            <div className={styles.item}>
              <div className={styles.title}>{t('support.second.title')}</div>
              <div className={styles.list}>
                {list.map((item, index) => (
                  <div className={styles.listItem}>
                    <i className={styles.icon}>
                      <img src={icons[index]} alt="" />
                    </i>
                    <div>
                      <div className={styles.title}>{t(`${item.title}`)}</div>
                      <div className={styles.text}>{t(`${item.text}`)}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className={`${styles.item} desk_only ${styles.imgWrap}`}>
              <img src={image1} alt="" className={styles.imgItem} />
              <img src={image2} alt="" className={styles.imgItem} />
              <img src={image3} alt="" className={styles.imgItem} />
            </div>
          </div>
        </section>
    );
};

export default ImageText;