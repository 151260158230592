import React, { useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import arrow from '../../../assets/images/arr-right.svg';
import styles from './Accordion.module.scss';

const Accordion = ({title}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation();

  const onTitleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const items = t(`${title}`, { returnObjects: true });

  const renderedItems = items.map((item, index) => {
    // const active = index === activeIndex ? 'active' : '';

    return (
      <div key={index} className={styles.item} >
        <div 
          className={`${styles.title} ${index === activeIndex ? styles.titleActive : ''}`} 
          onClick={() => onTitleClick(index)}
        >
          {item.title}
          <div className={styles.icon}>
            <img src={arrow} />
          </div>
        </div>
        <div className={`${styles.content} ${index === activeIndex ? styles.contentActive : ''}`}>
          <Trans>
            {item.text}
          </Trans>
        </div>
      </div>
    );
  });

  return <div className={styles.accordion}>
    {renderedItems}
  </div>;
};

export default Accordion;