import React from "react";
import styles from './Size.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import { Fade } from "react-reveal";

import bg from '../../../assets/images/bonus/bg1.webp';
import bg1 from '../../../assets/images/bonus/bg3.webp';

const Size = () => {
    const { t } = useTranslation();

    return (
        <section className={styles.wrap}>
          <div className={`container ${styles.container}`}>
            <div className={`${styles.left} ${styles.imgWrap}}`}>
              <div className={styles.title}><Trans>{t('bonus.four.title')}</Trans></div>
              <div className={styles.text}><Trans>{t('bonus.four.text')}</Trans></div>
              <div className="mt-btn"><Button>{t('btn.trading')}</Button></div>

              <div className={styles.bot}>
                <div className={`${styles.item} ${styles.item1}`}>
                  <img src={bg1} alt="" className={styles.bg} />
                  <div className={styles.itemContent}>
                    <div className={styles.itemTitle}>{t('bonus.four.list1-title')}</div>
                    <div className={styles.itemText}>{t('bonus.four.list1-text')}</div>
                  </div>
                </div>
                <div className={`${styles.item} ${styles.item2}`}>
                  <img src={bg1} alt="" className={styles.bg} />
                  <div className={styles.itemContent}>
                    <div className={styles.itemTitle}>{t('bonus.four.list2-title')}</div>
                    <div className={styles.itemText}>{t('bonus.four.list2-text')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <div className={`${styles.item} ${styles.item3}`}>
                <img src={bg} alt="" className={`${styles.bg} desk_only`} />
                <img src={bg1} alt="" className={`${styles.bg} mob_only`} />
                <div className={styles.itemContent}>
                  <div className={styles.itemTitle}>{t('bonus.four.list3-title')}</div>
                  <div className={styles.itemSubtitle}>{t('bonus.four.list3-subtitle')}</div>
                  <div className={styles.itemText}>{t('bonus.four.list3-text')}</div>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
};

export default Size;