import React from "react";
import styles from './Terms.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import { Fade } from "react-reveal";

import image from '../../../assets/images/bonus/image2.webp';

const Terms = () => {
    const { t } = useTranslation();

    return (
        <section className={styles.wrap}>
          <div className={`container ${styles.container}`}>
            <div className={`${styles.left} ${styles.imgWrap}}`}>
              <div className={styles.title}><Trans>{t('bonus.third.title')}</Trans></div>
              <div className={styles.text}><Trans>{t('bonus.third.text')}</Trans></div>
              <div className="mt-btn"><Button>{t('btn.trading')}</Button></div>
            </div>
            <div className={styles.right}>
              <img src={image} alt="" />
            </div>
          </div>
        </section>
    );
};

export default Terms;