import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Steps from '../components/GlodalComponents/Steps/Steps';
import Baner from '../components/BonusesPage/Banner/Banner';
import Bonuses from '../components/BonusesPage/Bonuses/Bonuses';
import Terms from '../components/BonusesPage/Terms/Terms';
import Size from '../components/BonusesPage/Size/Size';
import ImageText from '../components/BonusesPage/ImageText/ImageText';


function BonusesPage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Fargo Invest · Bonuses system</title>
        <meta name="title" content="Elevating Your Experience with Our Exclusive Bonus System" />
        <meta property="og:title" content="Elevating Your Experience with Our Exclusive Bonus System" />
        <meta property="twitter:title" content="Elevating Your Experience with Our Exclusive Bonus System" />

        <meta name="description" content="At Fargo Invest, we transcend the norms of typical trading platforms by unveiling an extensive bonus system. Delight in a hospitable start with our attractive sign-up bonus, boost your trading capital with deposit bonuses, and accumulate rewards in line with your trading activity." />
        <meta property="og:description" content="At Fargo Invest, we transcend the norms of typical trading platforms by unveiling an extensive bonus system. Delight in a hospitable start with our attractive sign-up bonus, boost your trading capital with deposit bonuses, and accumulate rewards in line with your trading activity." />
        <meta property="twitter:description" content="At Fargo Invest, we transcend the norms of typical trading platforms by unveiling an extensive bonus system. Delight in a hospitable start with our attractive sign-up bonus, boost your trading capital with deposit bonuses, and accumulate rewards in line with your trading activity." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='ovf-hidden bonus-page'>
        <Header />
        <Baner />
        <Bonuses />
        <Terms />
        <Size />
        <ImageText />
        <Steps />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default BonusesPage;
