import React from "react";
import styles from './Vision.module.scss';
import { Trans, useTranslation } from "react-i18next";

import icon from '../../../assets/images/check.svg';

const Vision = () => {
    const { t } = useTranslation();
    const list = t('about.third.list', { returnObjects: true });
    const leftList = list.slice(0,4);
    const rightList = list.slice(4,8);

    return (
        <section className={styles.wrap}>
          <div className="container">
            <div className={styles.title}><Trans>{t('about.third.title')}</Trans></div>
            <div className={styles.item}>
              <div className={`${styles.text} ${styles.mrBot}`}><Trans>{t('about.third.text0')}</Trans></div>
              <div className={`${styles.text} ${styles.mrBot}`}><Trans>{t('about.third.text1')}</Trans></div>
            </div>
            <div className={styles.item}>
              <div className={styles.list}>
                {leftList.map((item, index) => (
                  <div className={styles.listItem} key={index}>
                    {index > 0 && 
                      <i className={styles.icon}>
                        <img src={icon} alt="" />
                      </i>
                    }
                    <div className={`${styles.text} ${index === 0 ? styles.listTitle : ''}`}>{t(`${item.title}`)}</div>
                  </div>
                ))}
              </div>
              <div className={styles.list}>
                {rightList.map((item, index) => (
                  <div className={styles.listItem} key={index}>
                    <i className={styles.icon}>
                      <img src={icon} alt="" />
                    </i>
                    <div className={styles.text}>{t(`${item.title}`)}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
    );
};

export default Vision;