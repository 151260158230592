import React from "react";
import styles from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import image from '../../../assets/images/acctypes/image.webp';
import { Reveal } from "react-reveal";

const Banner = () => {
    const { t } = useTranslation();

    return (
        <section className={styles.wrap}>
          <Reveal effect="zoomOut">
            <div className={`${styles.container} container`}>
              <img src={image} alt="" className={styles.image} />
              <div className={styles.textBlock}>
                <h1 className={styles.title}>{t('acctypes.first.title0')}</h1>
                <h5 className={styles.title}>{t('acctypes.first.title1')}</h5>

                <div className={styles.text}>
                  {t('acctypes.first.text')}
                </div>
                <div className="mt-btn"><Button /></div>
              </div>
            </div>
          </Reveal>
        </section>
    );
};

export default Banner;