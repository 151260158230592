import React from "react";
import styles from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import image from '../../../assets/images/homepage/main1.webp';
import { Reveal } from "react-reveal";

const Banner = () => {
    const { t } = useTranslation();

    return (
        <section className={styles.wrap}>
          <Reveal effect="zoomOut">
            <div className={`${styles.container}`}>
              <div className={`${styles.left} ${styles.imgWrap}}`}>
                <img src={image} alt="" />
              </div>
              <div className={styles.right}>
                <h1 className={styles.title}>{t('main.first.title-0')}</h1>
                <h5 className={styles.subtitle}>{t('main.first.title-1')}</h5>

                <div className={styles.block}>
                  <div className={styles.text}>
                    {t('main.first.text')}
                  </div>
                  <div className="mt-btn"><Button /></div>
                </div>
              </div>
            </div>
          </Reveal>
        </section>
    );
};

export default Banner;