import HomePage from '../pages/HomePage';
import AccountsPage from '../pages/AccountsPage';
import BonusesPage from '../pages/BonusesPage';
import TradingPage from '../pages/TradingPage';
import SupportPage from '../pages/SupportPage';
import AboutPage from '../pages/AboutPage';

export const routes = [
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/trading', element: TradingPage },
    { path: '/:lang/accounts', element: AccountsPage },
    { path: '/:lang/bonuses', element: BonusesPage },
    { path: '/:lang/support', element: SupportPage },
    { path: '/:lang/about', element: AboutPage },
];


export const headerRoutes = [
    { path: 'trading', text: 'header.trading' },
    { path: 'accounts', text: 'header.accounts' },
    { path: 'bonuses', text: 'header.bonuses' },
    { path: 'support', text: 'header.support' },
    { path: 'about', text: 'header.about' },
];