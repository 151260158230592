import React from "react";
import styles from './Bonuses.module.scss';
import { useTranslation } from "react-i18next";

import icon1 from '../../../assets/images/bonus/icon1.svg';
import icon2 from '../../../assets/images/bonus/icon2.svg';
import icon3 from '../../../assets/images/bonus/icon3.svg';

const Bonuses = () => {
    const { t } = useTranslation();
    const list = t('bonus.second.list', { returnObjects: true });
    const icons = [icon1, icon2, icon3];

    return (
        <section className={styles.wrap}>
          <div className={`container ${styles.container}`}>
            {list.map((item, index) => (
              <div className={styles.item} key={index}>
                <i className={styles.icon}>
                  <img src={icons[index]} alt="" />
                </i>
                <div className={styles.title}>{t(`${item.title}`)}</div>
                <div className={styles.text}>{t(`${item.text}`)}</div>
              </div>
            ))}
          </div>
        </section>
    );
};

export default Bonuses;