import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Baner from '../components/TradingAssets/Banner/Banner';
import List from '../components/TradingAssets/List/List';
import ImageText from '../components/TradingAssets/ImageText/ImageText';
import Steps from '../components/GlodalComponents/Steps/Steps';

function ProductsPage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Fargo Invest · Trading assets</title>
        <meta name="title" content="Explore a World of Diverse Trading Opportunities at Fargo Invest!" />
        <meta property="og:title" content="Explore a World of Diverse Trading Opportunities at Fargo Invest!" />
        <meta property="twitter:title" content="Explore a World of Diverse Trading Opportunities at Fargo Invest!" />

        <meta name="description" content="Fargo Invest offers an extensive selection of trading assets and instruments, tailored to accommodate various trading styles and strategies. From leading cryptocurrencies to emerging altcoins and innovative tokenized assets, our platform provides a gateway to an ever-evolving market environment." />
        <meta property="og:description" content="Fargo Invest offers an extensive selection of trading assets and instruments, tailored to accommodate various trading styles and strategies. From leading cryptocurrencies to emerging altcoins and innovative tokenized assets, our platform provides a gateway to an ever-evolving market environment." />
        <meta property="twitter:description" content="Fargo Invest offers an extensive selection of trading assets and instruments, tailored to accommodate various trading styles and strategies. From leading cryptocurrencies to emerging altcoins and innovative tokenized assets, our platform provides a gateway to an ever-evolving market environment." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='ovf-hidden trading-page'>
        <Header />
        <Baner />
        <List />
        <ImageText />
        <Steps />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default ProductsPage;
