import React from "react";
import styles from './ImageText.module.scss';
import { useTranslation } from "react-i18next";
import { Reveal } from "react-reveal";

import icon from '../../../assets/images/check.svg';
import image1 from '../../../assets/images/tradingassets/image2.webp';
import image2 from '../../../assets/images/tradingassets/image3.webp';
import Button from "../../GlodalComponents/Button/Button";

const ImageText = () => {
    const { t } = useTranslation();
    const list = t('trading.third.list', { returnObjects: true });

    return (
        <section className={styles.wrap}>
          <div className={`${styles.container} container`}>
            <div className={styles.item}>
              <div className={styles.title}>{t('trading.third.title0')}</div>
              <div className={styles.text}>{t('trading.third.text0')}</div>
              <div className="mob_only">
                <div className={styles.list}>
                  {list.map((item, index) => (
                    <div className={styles.item} key={index}>
                      <i className={styles.icon}>
                        <img src={icon} alt="" />
                      </i>
                      <div className={styles.title}>{t(`${item.title}`)}</div>
                    </div>
                  ))}
                </div>
                <div className={styles.text}>{t('trading.third.text2')}</div>
              </div>
              <div className={styles.imgWrap}>
                <img src={image1} alt="" />
              </div>
              <div className={styles.title}>{t('trading.third.title1')}</div>
              <div className={styles.text}>{t('trading.third.text1')}</div>
            </div>

            <div className={`${styles.item} desk_only`}>
              <div className={styles.imgWrap}>
                <img src={image2} alt="" />
              </div>
              <div className={styles.list}>
                {list.map((item, index) => (
                  <div className={styles.item} key={index}>
                    <i className={styles.icon}>
                      <img src={icon} alt="" />
                    </i>
                    <div className={styles.title}>{t(`${item.title}`)}</div>
                  </div>
                ))}
              </div>
              <div className={styles.text}>{t('trading.third.text2')}</div>
              <div className="mt-btn desk_only">
                <Button>{t('btn.trading')}</Button>
              </div>
            </div>
          </div>
        </section>
    );
};

export default ImageText;