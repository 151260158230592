import React from "react";
import styles from './ImageText.module.scss';
import { useTranslation } from "react-i18next";
import { Reveal } from "react-reveal";
import Button from "../../GlodalComponents/Button/Button";

import image1 from '../../../assets/images/bonus/image4.webp';
import image2 from '../../../assets/images/bonus/image3.webp';

const ImageText = () => {
    const { t } = useTranslation();

    return (
        <section className={styles.wrap}>
          <div className={`${styles.container} container`}>
            <div className={styles.item}>
              <div className={styles.title}>{t('bonus.five.title0')}</div>
              <div className={styles.text}>{t('bonus.five.text0')}</div>
              {/* <div className="mob_only">
                <div className={styles.text}>{t('bonus.five.text2')}</div>
              </div> */}
              <div className={styles.imgWrap}>
                <img src={image1} alt="" />
              </div>
              <div className="mt-btn desk_only">
                <Button>{t('btn.trading')}</Button>
              </div>
            </div>

            <div className={styles.item}>
              <div className={`${styles.imgWrap} desk_only`}>
                <img src={image2} alt="" />
              </div>
              <div className={styles.title}>{t('bonus.five.title1')}</div>
              <div className={styles.text}>{t('bonus.five.text1')}</div>
              <div className={styles.title}>{t('bonus.five.title2')}</div>
              <div className={styles.text}>{t('bonus.five.text20')}</div>
              <div className={styles.text}>{t('bonus.five.text21')}</div>
            </div>
          </div>
        </section>
    );
};

export default ImageText;