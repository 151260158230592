import React, { useRef, useEffect } from "react";
import styles from './Mission.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import icon from '../../../assets/images/arr-right.svg';
import image from '../../../assets/images/about/image2.webp';

const Mission = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);
  const { t } = useTranslation();
  const slides = t('about.second.list', { returnObjects: true });
  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.params.navigation.prevEl = prevRef.current;
      swiperRef.current.params.navigation.nextEl = nextRef.current;
      swiperRef.current.navigation.update();
    }
  }, []);

  return (
      <section className={styles.wrap}>
        <div className={styles.container}>
          <div className={styles.imgWrap}>
            <img src={image} alt="" />
          </div>
          <div className={styles.right}>
            <div className={styles.blockText}>
              <div className={styles.title}>{t('about.second.title')}</div>
              <div className={styles.text}><Trans>{t('about.second.text')}</Trans></div>
            </div>
            <div className={styles.sliderWrap}>
              <div className={styles.sliderBtns}>
                <button ref={prevRef} className={`${styles.sliderBtn} ${styles.sliderBtnPrev}`}>
                  <img src={icon} alt="Prev" />
                </button>
                <button ref={nextRef} className={`${styles.sliderBtn} ${styles.sliderBtnNext}`}>
                  <img src={icon} alt="Next" />
                </button>
              </div>
              <Swiper
                modules={[Navigation]}
                spaceBetween={69}
                slidesPerView={2.4}
                slidesPerGroup={1}
                loop={true}
                navigation={{
                  prevEl: prevRef.current,
                  nextEl: nextRef.current,
                }}
                onBeforeInit={(swiper) => {
                  swiperRef.current = swiper;
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  769: {
                    slidesPerView: 2.4,
                  },
                }}
              >
                {slides.map((item, index) => (
                  <SwiperSlide key={index} className={styles.sliderItem}>
                    <div className={styles.slideTitle}>{t(`${item.title}`)}</div>
                    <div className={styles.slideText}>{t(`${item.text}`)}</div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
  );
};

export default Mission;