import React from "react";
import styles from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import { Reveal } from "react-reveal";

import image from '../../../assets/images/tradingassets/image1.webp';
import mobile from '../../../assets/images/tradingassets/mobile.webp';
import icon from '../../../assets/images/check.svg';
import Button from "../../GlodalComponents/Button/Button";

const Banner = () => {
    const { t } = useTranslation();
    const list = t('trading.first.list', { returnObjects: true });

    return (
        <section className={styles.wrap}>
          <Reveal effect="zoomOut">
            <div className={`${styles.container}`}>
              <div className={`${styles.left} ${styles.imgWrap}}`}>
                <img src={image} alt="" className="desk_only" />
                <img src={mobile} alt="" className="mob_only" />
              </div>
              <div className={styles.right}>
                <h1 className={styles.title}>{t('main.first.title-0')}</h1>
                <h5 className={styles.subtitle}>{t('trading.first.title')}</h5>
                <div className={styles.text}>
                  {t('trading.first.text')}
                </div>
                <div className="mt-btn mob_only">
                  <Button>{t('btn.trading')}</Button>
                </div>

                <div className={`${styles.list} desk_only`}>
                  {list.map((item, index) => (
                    <div className={styles.item} key={index}>
                      <i className={styles.icon}>
                        <img src={icon} alt="" />
                      </i>
                      <div className={styles.text}>{t(`${item.title}`)}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={`${styles.list} mob_only`}>
                  {list.map((item, index) => (
                    <div className={styles.item} key={index}>
                      <i className={styles.icon}>
                        <img src={icon} alt="" />
                      </i>
                      <div className={styles.text}>{t(`${item.title}`)}</div>
                    </div>
                  ))}
                </div>
            </div>
          </Reveal>
        </section>
    );
};

export default Banner;