import React from "react";
import styles from './Steps.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

const Steps = () => {
    const { t } = useTranslation();

    const list = t('main.seven.list', { returnObjects: true });

    return (
        <section className={styles.wrap}>
          <div className={`${styles.container} container`}>
            <div className={styles.title}><Trans>{t('main.seven.title')}</Trans></div>
            <div className={styles.bot}>
              {list.map((item, index) => (
                <div className={styles.item} key={index}>
                  <div className={styles.index}>{index + 1}</div>
                  <div className={styles.text}>{t(`${item.title}`)}</div>
                </div>
              ))}
            </div>
          </div>
        </section>
    );
};

export default Steps;