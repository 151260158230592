import React from 'react';
import { useTranslation } from "react-i18next";
import logo from '../../../assets/images/logo.svg';
import styles from './Footer.module.scss';

const Footer = () => {

  const { t } = useTranslation();
  const { hostname } = document.location;
  
  return (
    <footer className={styles.footer}>
      <div className='container'>
        <div className={styles.footerContent}>
          <div className={styles.leftSide}>
            <div className={styles.logoWrap}>
              <img src={logo} alt="Logo" className={styles.headerLogo} />
            </div>
            <div className={styles.footerNav}>
              <div className={styles.itemBlock} >
                {/* <a href="" className={styles.navTitle}>{t('footer.link-1')}</a> */}
                <div className={styles.itemTitle}>Fargo Invest</div>
                <div>
                  <a href="tel:+971521045528" className={styles.itemTitle}>+971521045528</a>
                </div>
                <div>
                  <a href={`mailto:support@${hostname}`} className={styles.itemTitle}>
                    support@{hostname}
                  </a>
                </div>
              </div>
              <div>
                <div className={styles.itemBlock}>
                  <a href="/docs/Client_agreement.pdf" target='_blank' className={styles.navTitle}>{t('footer.docs.clientag')}</a>
                </div>
                <div className={styles.itemBlock}>
                  <a href="/docs/CONFLICT_OF_INTERESTS.pdf" target='_blank' className={styles.navTitle}>{t('footer.docs.conflict')}</a>
                </div>
                <div className={styles.itemBlock}>
                  <a href="/docs/PRIVACY_POLICY.pdf" target='_blank' className={styles.navTitle}>{t('footer.docs.privacy')}</a>
                </div>
                <div className={styles.itemBlock}>
                  <a href="/docs/FARGO_INVEST_FCA.pdf" target='_blank' className={styles.navTitle}>{t('footer.docs.fca')}</a>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.rightSide}>
            <div className={styles.navTitle}>{t('footer.title')}</div>
            <div className={styles.text}>
              <p>{t('footer.text')}</p>
              <p>{t('footer.text-1')}</p>
            </div>
            <div className={styles.copyright}>{t('footer.copyright')}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;