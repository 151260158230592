import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Steps from '../components/GlodalComponents/Steps/Steps';
import Baner from '../components/AboutUs/Banner/Banner';
import Vision from '../components/AboutUs/Vision/Vision';
import Mission from '../components/AboutUs/Mission/Mission';
import ClientsAbout from '../components/AboutUs/ClientsAbout/ClientsAbout';

function AboutPage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Fargo Invest · About us</title>
        <meta name="title" content="Fargo Invest - Charting Your Path to Cryptocurrency Success" />
        <meta property="og:title" content="Fargo Invest - Charting Your Path to Cryptocurrency Success" />
        <meta property="twitter:title" content="Fargo Invest - Charting Your Path to Cryptocurrency Success" />

        <meta name="description" content="Enter the world of Fargo Invest, a distinguished platform in cryptocurrency trading, celebrated for its legacy and dedication to elevating traders globally. Our core mission is focused on bridging the gap between traditional finance and the burgeoning realm of digital assets." />
        <meta property="og:description" content="Enter the world of Fargo Invest, a distinguished platform in cryptocurrency trading, celebrated for its legacy and dedication to elevating traders globally. Our core mission is focused on bridging the gap between traditional finance and the burgeoning realm of digital assets." />
        <meta property="twitter:description" content="Enter the world of Fargo Invest, a distinguished platform in cryptocurrency trading, celebrated for its legacy and dedication to elevating traders globally. Our core mission is focused on bridging the gap between traditional finance and the burgeoning realm of digital assets." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='ovf-hidden about-page'>
        <Header />
        <Baner />
        <Mission />
        <Vision />
        <ClientsAbout />
        <Steps />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default AboutPage;
