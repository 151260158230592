import React from "react";
import styles from './TextAccordion.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import Accordion from '../Accordion/Accordion'

const TextAccordion = () => {
    const { t } = useTranslation();

    return (
        <section className={styles.wrap}>
          <div className={`container ${styles.container}`}>
            <div className={styles.item}>
              <div className={styles.title}>{t('acctypes.third.title0')}</div>
              <div className={`${styles.title} ${styles.mb}`}>{t('acctypes.third.title1')}</div>
              <div className={styles.text}>{t('acctypes.third.text0')}</div>
              <div className={styles.text}>{t('acctypes.third.text1')}</div>
              <div className="mt-btn"><Button>{t('btn.trading')}</Button></div>
            </div>
            <div className={styles.item}>
              <Accordion title={'acctypes.third.accordion'} />
            </div>
          </div>
        </section>
    );
};

export default TextAccordion;