import React from "react";
import styles from './Various.module.scss';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

import image0 from '../../../assets/images/homepage/main2.webp';
import image1 from '../../../assets/images/homepage/main3.webp';
import image2 from '../../../assets/images/homepage/main4.webp';
import image3 from '../../../assets/images/homepage/main5.webp';

const Various = () => {
    const { t } = useTranslation();

    const data = t('main.third.list', { returnObjects: true });
    const images = [image0, image1, image2, image3];

    return (
        <section className={styles.wrap}>
          <div className={`container ${styles.container}`}>
            <div className={`${styles.top} ${styles.imgWrap}}`}>
              <div className={styles.title}>{t('main.third.title')}</div>
              <div className={styles.subtitle}>{t('main.third.text')}</div>
            </div>
            <div className={styles.bot}>
              {data.map((item, index) => (
                <Fade 
                  key={index}
                  left={index % 4 === 0}
                  right={index % 4 === 3}
                  bottom={index % 4 === 2 || index % 4 === 1}
                >
                  <div className={styles.item}>
                    <div className={`${styles.imgWrap} absolute`}>
                      <img src={images[index]} alt="" />
                    </div>
                    <div className={styles.content}>
                      <div className={styles.title}>{t(`${item.title}`)}</div>
                      <div className={styles.text}>{t(`${item.text}`)}</div>
                    </div>
                  </div>
                </Fade>
              ))}
            </div>
          </div>
        </section>
    );
};

export default Various;