import React, { useRef, useEffect } from "react";
import styles from './ClientsAbout.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import icon from '../../../assets/images/arr-right.svg';
import image1 from '../../../assets/images/about/image3.webp';
import image2 from '../../../assets/images/about/image5.webp';
import image3 from '../../../assets/images/about/image4.webp';

const ClientsAbout = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);
  const { t } = useTranslation();
  const slides = t('about.four.slider', { returnObjects: true });
  const images = [image1, image2, image3];

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.params.navigation.prevEl = prevRef.current;
      swiperRef.current.params.navigation.nextEl = nextRef.current;
      swiperRef.current.navigation.update();
    }
  }, []);

  return (
      <section className={styles.wrap}>
        <div className={`${styles.container} container`}>
          <div className={styles.title}>{t('about.four.title')}</div>
          <div className={styles.sliderWrap}>
            <Swiper
              modules={[Navigation]}
              spaceBetween={5}
              slidesPerView={1}
              slidesPerGroup={1}
              loop={true}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
            >
              {slides.map((item, index) => (
                <SwiperSlide key={index} className={`${styles.sliderItem} noBg`}>
                  <div className={styles.imgWrap}>
                    <img src={images[index]} alt="" />
                  </div>
                  <div className={styles.slideTextBlock}>
                    <div className={styles.slideText}>{t(`${item.text}`)}</div>
                    <div className={styles.slideTitle}>{t(`${item.title}`)}</div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className={styles.sliderBtns}>
              <button ref={prevRef} className={`${styles.sliderBtn} ${styles.sliderBtnPrev}`}>
                <img src={icon} alt="Prev" />
              </button>
              <button ref={nextRef} className={`${styles.sliderBtn} ${styles.sliderBtnNext}`}>
                <img src={icon} alt="Next" />
              </button>
            </div>
          </div>
        </div>
      </section>
  );
};

export default ClientsAbout;