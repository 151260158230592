import React from "react";
import styles from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import { Reveal } from "react-reveal";

import image from '../../../assets/images/about/image1.webp';
import bg from '../../../assets/images/about/line.webp';

const Banner = () => {
    const { t } = useTranslation();

    return (
        <section className={styles.wrap}>
          <Reveal effect="zoomOut">
            <div className={`${styles.container}`}>
              <div className={`${styles.left} ${styles.imgWrap}}`}>
                <img src={image} alt="" className={styles.index} />
                <img src={bg} alt="" className={styles.absolute} />
              </div>
              <div className={styles.right}>
                <h1 className={styles.title}><Trans>{t('about.first.title')}</Trans></h1>
                <h5 className={styles.subtitle}><Trans>{t('about.first.subtitle')}</Trans></h5>

                <div className={styles.block}>
                  <div className={styles.text}>
                    <Trans>{t('about.first.text')}</Trans>
                  </div>
                  <div className="mt-btn"><Button /></div>
                </div>
              </div>
            </div>
          </Reveal>
        </section>
    );
};

export default Banner;