import React from "react";
import styles from './Extensive.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import { Fade } from "react-reveal";

import image from '../../../assets/images/homepage/main7.webp';

const Extensive = () => {
    const { t } = useTranslation();

    const list = t('main.second.list', { returnObjects: true });

    return (
        <section className={styles.wrap}>
          <div className={`container ${styles.container}`}>
            <div className={`${styles.left} ${styles.imgWrap}}`}>
              <div className={styles.title}>{t('main.six.title')}</div>
              <div className={styles.subtitle}>{t('main.six.subtitle')}</div>
              <div className={styles.text}><Trans>{t('main.six.text')}</Trans></div>
              <div className="mt-btn"><Button /></div>
            </div>
            <div className={styles.right}>
              <img src={image} alt="" />
            </div>
          </div>
        </section>
    );
};

export default Extensive;