import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Banner from '../components/HomePage/Banner/Banner';
import Reasons from '../components/HomePage/Reasons/Reasons';
import Various from '../components/HomePage/Various/Various';
import Tools from '../components/HomePage/Tools/Tools';
import Bonus from '../components/HomePage/Bonus/Bonus';
import Slider from '../components/HomePage/Slider/Slider';
import Extensive from '../components/HomePage/Extensive/Extensive';
import Steps from '../components/GlodalComponents/Steps/Steps';

function HomePage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Fargo Invest</title>
        <meta name="title" content="Fargo Invest: Harness the Potential of Cryptocurrency Trading!" />
        <meta property="og:title" content="Fargo Invest: Harness the Potential of Cryptocurrency Trading!" />
        <meta property="twitter:title" content="Fargo Invest: Harness the Potential of Cryptocurrency Trading!" />

        <meta name="description" content="Step into the realm of Fargo Invest, your portal to the dynamic and thrilling universe of cryptocurrency trading. Catering to both seasoned traders and beginners in the crypto arena, our advanced platform is crafted to enhance the trading experience for users of all expertise levels." />
        <meta property="og:description" content="Step into the realm of Fargo Invest, your portal to the dynamic and thrilling universe of cryptocurrency trading. Catering to both seasoned traders and beginners in the crypto arena, our advanced platform is crafted to enhance the trading experience for users of all expertise levels." />
        <meta property="twitter:description" content="Step into the realm of Fargo Invest, your portal to the dynamic and thrilling universe of cryptocurrency trading. Catering to both seasoned traders and beginners in the crypto arena, our advanced platform is crafted to enhance the trading experience for users of all expertise levels." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='ovf-hidden home-page'>
        <Header />
        <Banner />
        <Reasons />
        <Various />
        <Tools />
        <Bonus />
        <Slider />
        <Extensive />
        <Steps />
        <Footer />
      </div>
    </HelmetProvider>
  )
}

export default HomePage;
