import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import { Fade } from "react-reveal";
import styles from './Tools.module.scss';

import icon from '../../../assets/images/check.svg';
import image from '../../../assets/images/homepage/main6.webp';


const Tools = () => {
    const { t } = useTranslation();

    const list = t('main.third.list', { returnObjects: true });

    return (
        <section className={styles.wrap}>
          <div className={styles.title}>{t('main.four.title')}</div>
          <div className={`container ${styles.container}`}>
            <div className={styles.left}>
              <img src={image} alt="" />
            </div>
            <div className={styles.right}>
              <div className={styles.text}>{t('main.four.text')}</div>
              <div className={styles.list}>
                {list.map((item, index) => (
                  <div className={styles.item} key={index}>
                    <i className={styles.icon}>
                      <img src={icon} alt="" />
                    </i>
                    <div className={styles.text}>{t(`${item.title}`)}</div>
                  </div>
                ))}
              </div>
              <div className={styles.text}>{t('main.four.text1')}</div>
              <div className="mt-btn"><Button /></div>
            </div>
          </div>
        </section>
    );
};

export default Tools;